import { IBookingStatus} from "./bookingStatusTypes";
import {IAnalyticsKeys, IVenueMinimal} from "shared-types/WidgetTypes";

/**
 * @todo: it may make sense now to change 'WaitList' to 'WalkIn', since NBI-1453 made it impossible to have a waitlist
 * item unless it is either standby or walkin. This however would involve a back end change as well. severity: medium
 */
export enum BookingType {
  Booking = 'Booking',
  WaitList = 'WaitList',
  StandbyList = 'StandbyList'
}

export enum BookingBaseType {
  booking = 'booking',
  event = 'event'
  // @todo: determine if we also need private function here
}


export enum BookingMethods {
  phone = 'phone',
  walkin = 'walkin',
  internet = 'online',
  email = 'email'
}
export enum PaymentTypes {
  NoPayment = 'NoPayment', // Default
  Deposit = 'Deposit',
  FullPayment = 'FullPayment',
  PreAuth = 'PreAuth',
  FunctionPayment = 'FunctionPayment',
  MenuOptions = 'MenuOptions' // Only a map/type view
}
export enum PricingTypes {
  PerPax = 'PerPax',
  PerBooking = 'PerBooking'
}

export interface ITableOptionValidity {
  free: boolean;
  valid: boolean;
}

export interface ITableOptionValidityRef extends ITableOptionValidity {
  table?: ITableItem;
}

export interface ITableItemAbstract extends ITableOptionValidity {
  _id: string; // "table_3Z9QEO88JE2P4_1597633785419"
  sectionId: string; // "section_9BTVP2I5KZMWI"
  tablesList?: ITableItem[]; // this is a list of table objects, used when a table join exists
  priority: number;
  capacity: number;
  availableOption: string; // "all"
  minCapacity: number; // 1
  name: string; // "Big table"
  online: boolean;
  order: number;
  shape: string; // "rect"
  shared: boolean;
  type: string; // "table"
  walkinOnly: boolean;
  rotation?: number;
  position?: {x: number, y: number};
  chairs?: any; // {0: 1, 1: 1},
}

export interface ITableItem extends ITableItemAbstract {
  tables?: string[]; // this is a list of table ids, used when a table join exists.
}

/**
 * @todo: we may need to replace IPaymentDetails with IPaymentDetailsBase - further investigation needed. severity: medium
 */
export interface IPaymentDetails {
  menuOptions: string[]; // ["menuoption_F2CKA82APR5PH_1586299562563"]
  optionsFrom: unknown;
  optionsTo: unknown;
  paymentType: PaymentTypes;
  peopleRequired: number;
  singleMenuPerBooking: boolean;
  price?: number;
}


export interface ISimpleBookingOption {
  description: string;
  id: string; // "menuoption_UAQAZETMPS9T7_1573535792474"
  label: string;
  link: string;
  name: string;
  price: number;
  provider: string; // "Nbi"
  tag: string; // "tag_birthday"
  paymentType: servicePaymentType;
  paymentTypeOverride?: IPaymentTypeOverride
}


export interface IPaymentTypeOverride {
  paymentType: servicePaymentType; // for when child booking options dictate the payment type (on noPayment parents)
  amount?: number;
  hasError: boolean;
}


export interface IServicePaymentOption extends ISimpleBookingOption {
  childMenuOptionIds?: string[];
  explicitChildMenuOptionIds?: string[];
}

export interface IServicePaymentDetails {
  hasPromotion: boolean;
  maxPeoplePerBookingOverride: number;
  options: IServicePaymentOption[];
  optionsUpsell?: IServicePaymentOption[];
  optionsFrom: unknown;
  optionsTo: unknown;
  paymentType: servicePaymentType;
  peopleRequired: number;
  price: number; // dollars
  singleMenuPerBooking: boolean;
  pricingType?: PricingTypes;
  fee?: number;
  upsellHeading?: string;
  upsellDescription?: string;
}


/**
 * Properties for pre-auth payment type
 */
export interface IPaymentSummary {
  amount: number;
  amountDue: number;
  amountHeld: string; // "2.00"
  amountPaid: number;
  currencyCode: string;
  hasPromotion: boolean;
  paymentType: PaymentTypes;
  preAuthChargedAmount: number;
  preAuthChargeIfWithinHours: number;
  preAuthHeldDate: string; // "2022-04-19T07:34:00.2929921+00:00"
  preAuthReleasedAmount: number;
  preAuthStatus: string;
  pricingType?: string;
}

/**
 * Properties for non pre-auth payment type
 */
export interface IPaymentPending {
  discountAmount: number;
  fee: number;
  nbiFee: number;
  paymentType: PaymentTypes;
  peopleRequired: number;
  price: number;
  transactionFeeDescription: string;
}

export interface IPaymentType {
  amount: number, // 132.5,
  paymentTypeName: servicePaymentType // "FullPayment"
  amountDue: number;
  discountAmount: number;
}

export interface IBooking {
  sectionMaxOccupancy?: string;
  bookedAt: Date; // Thu Sep 03 2020 13:24:55 GMT+1000 (Australian Eastern Standard Time) {}
  bookingEnd: Date; // Fri Sep 04 2020 09:30:00 GMT+1000 (Australian Eastern Standard Time) {}
  bookingRef: string; //"03495357"
  bookingMin: number;
  company: string; // "GoG"
  customerId: string; // "customer_9ea76edb-914f-4526-b3e8-bdcbc208ce91"
  customersCovid: unknown[];
  duration: string | number; // should be number (mins) but due to bug, could be string
  email: string; // "peter@quill.com"
  firstName: string; // "Peter"
  hasCustomerNotes: boolean;
  hasManagerNotes: boolean;
  notes: string;
  hasVisitedMultipleVenues: boolean;
  selectedMenuOptions?: IBookingMenuOption[];
  selectedOptions?: ISavedBookingSelectedOptions[];
  selectedUpsellOptions?: IBookingMenuOption[];
  lastModifiedDate: string; // "2020-09-03T03:24:55.403Z"
  lastName: string; //"Quill"
  locked: boolean;
  method: BookingMethods;
  orgTime: string; // "2020/09/04 09:00"
  paymentDetails: IPaymentDetails;
  paymentSummary?: IPaymentSummary;
  paymentPending?: IPaymentPending;
  people: number; // 2
  phone: string;
  preferredSectionId: string; // "section_9BTVP2I5KZMWI"
  primaryTableId: string;
  serviceId: string; // "service_MWNR2VZS6BAO1_1587075416493"
  standByConfirmationExpiry?: string;
  status: IBookingStatus;
  tables: ITableItem[];
  tags: unknown[];
  time: Date; // Fri Sep 04 2020 09:00:00 GMT+1000 (Australian Eastern Standard Time) {}
  isStandbyListFlexibleTime?: boolean;
  type: BookingBaseType; // "booking"
  bookingType: BookingType; // 0
  sharedOverCapacity: boolean;
  overdue: boolean;
  isManualJoin: boolean;
  functionTracker?: boolean;
  _id: string; // "booking_2020-09-04_QLBY104Y0T0W2_1599103495357"
  _rev?: string; // gets added after saved to pouch
}

export interface ICustomer {
  birthdayDate?: null
  birthdayMonth?: null
  birthdayYear?: null
  company?: string;
  email: string; // "peter@parker.com"
  firstName: string; // "Peter"
  fullName?: string; // "Peter Parker"
  hasVisitedMultipleVenues?: boolean;
  lastModifiedDate?: string; // "2020-09-22T03:33:10.3767529+00:00"
  lastName: string; // "Parker"
  managerNotes?: string;
  noShowCount?: number;
  notes?: string;
  phone: string;// "+61414000111"
  phone2?: string;
  phoneNational?: string; //"0414562166"
  postcode?: unknown;
  streetAddress?: string;
  subscribed?: boolean;
  suburb?: string;
  tags?: IBookingTag[];
  type?: string; // "customer"
  venueIds?: unknown[];
  _id?: string; // "customer_8b75784d-b292-46dd-bec6-ba92daa7c911"
  _rev?: string;
  id?: string; // "customer_3357019e-0254-4127-a0e8-634e5fc5cb9b"
  country?: string;
}

export enum ServiceEventType {
  PrivateFunction = 'PrivateFunction',
  Event = 'Event'
}

export interface ILayout {
  name: string;
  sectionId: string;
  tables: ITableItem[];
  order: number;
  tablesJoins: ITableItem[];
  structures: unknown[];
  _id: string;
  box: { h: number, w: number, x: number, y: number, x2: number, y2: number };
}


/**
 * similar to IServicePaymentDetails
 * @todo: We may need to replace all instances of IPaymentDetails with IPaymentDetailsBase, but needs further investigation. Severity: medium
 */
export interface IPaymentDetailsBase {
  discountPercentage: number; // 90
  menuOptions: string[] // ["menuoption_EJUCQOQA94JTG_1587697160128"]
  paymentType: PaymentTypes;
  peopleRequired: number; // 1
  promotionCode: string; // "halfprice"
  singleMenuPerBooking: boolean;
  pricingType: PricingTypes;
  price?: number;
  menuOptionsUpsell?: string[];
  upsellDescription?: string;
  upsellHeading?: string;
}

export interface IAdditionalPaymentDetails extends IPaymentDetailsBase {
  optionsFrom: string; // "2021/03/23 00:00"
  optionsTo: string; // "2021/03/30 00:00"
}

export interface IServiceItem {
  _id: string; // "service_MWNR2VZS6BAO1_1587075416493"
  type: string; // "AllDay", 'Event', 'event'
  eventType?: ServiceEventType;
  bookingPAX?: number;
  startTime: Date; // Fri Sep 04 2020 06:00:00 GMT+1000 (Australian Eastern Standard Time) {}
  endTime: Date; // Fri Sep 04 2020 22:00:00 GMT+1000 (Australian Eastern Standard Time) {}

  sectionLayouts: ISectionLayoutItem[];

  Colour: string; // "booking-time-orange"
  active: boolean;
  additionalPaymentDetails: IAdditionalPaymentDetails[];
  bookingDuration: number; // 30
  description: string;
  index: number; // 0
  intervalsCustom: {times: string, values: string|number}[];
  lastBookingTime: Date; // Fri Sep 04 2020 21:45:00 GMT+1000 (Australian Eastern Standard Time) {}
  name: string; // "All day"
  online: boolean;
  paymentDetails: IPaymentDetails;
  policyAgreement: boolean;
  policyAgreementText: string; // "Agree to stuff"
  tablesIdsList: string[]; // ["table_S2HVR52GTHI60_1585723196136"]

  enableCustomPaxPerIntervals: boolean;
  maxCoversPerInterval: number;
  maxBookingsPerInterval: number;

  boookingInterval?: number;
  firstBookingTime?: Date;
}

export interface ISectionLayoutItem {
  sectionId: any;
  sectionNotUsed: boolean;
  layoutId: any;
  sectionClosed: boolean;
}

export interface IServiceLayoutItem {
  name: string; // "All day"
  serviceIndex: number; // 0
  serviceId: string; //"service_MWNR2VZS6BAO1_1587075416493"

  endMin: number; // 1320
  endTime: string; // Fri Sep 04 2020 22:00:00 GMT+1000 (Australian Eastern Standard Time) {}
  layoutId: string; // "layout_VWN60GYEO62GN"
  sectionClosed: boolean;
  sectionId: string; // "section_9BTVP2I5KZMWI"
  sectionNotUsed: boolean;
  sectionOpen: boolean;
  startMin: number; // 360
  startTime: string; // Fri Sep 04 2020 06:00:00 GMT+1000 (Australian Eastern Standard Time) {}
  totalMin: number; // 960
}

export enum BlockoutType {
  event = 'event',
  blockout = 'blockout'
}

export interface IBlockout {
  _id: string;
  startDateTime: Date;
  endDateTime: Date;
  blockedSections: string[];
  blockedTables: ITableItem[];
  type: BlockoutType;
}

export interface IBookingMenuOptionExtras {
  isSameForAll: boolean;
  explicitChildMenuOptions: IBookingMenuOption[][]; // multi-dimensional array
  implicitChildMenuOptions: IBookingMenuOption[];
}

export interface IBookingMenuOptionExtrasUpdater extends IBookingMenuOptionExtras {
  parentId?: string;
  parentLabel: string;
  upsellDescription?: string;
  parentQuantity: number;
}

export interface IBookingMenuOption {
  quantity: number;
  menuOptionId: string;
  extras?: IBookingMenuOptionExtras; // expanded list of child booking options
  isUpsellItem?: boolean; // will be true for upsell BO
}
export interface ISavedBookingMenuOption {
  quantity: number;
  menuOptionId: string;
  extras?: ISavedExtrasOption[]; // flat list of child booking options for backend
}

export interface ISavedExtrasOption {
  quantity: number;
  menuOptionId: string;
  isExplicit: boolean;
}


export interface ISavedBookingSelectedOptions {
  extras: ISavedExtras[];
  id: string;// "menuoption_GCYRCFH5MFORV_1589160518076"
  label: string; // "free buffet"
  name: string; // "free buffet"
  paymentType: servicePaymentType;
  price: number; // 0
  quantity: number; // 2
  isUpsellItem?: boolean
}

export interface ISavedExtras {
  id: string; // "menuoption_YOSOQUWBWJO8N_1587443464464"
  isExplicit: boolean; // true
  label: string; // "Free lunch"
  name: string; // "Free lunch"
  paymentType: servicePaymentType;
  price: number; // ISavedBookingSelectedOptions
  quantity: number; // 1
}


export enum servicePaymentType {
  fullPayment = "FullPayment",
  noPayment = "NoPayment",
  deposit = "Deposit",
  preAuth = "PreAuth",
  functionPayment = "FunctionPayment",
}

export enum TimeStatus {
  available = 'available',
  unavailable = 'unavailable'
}

export interface ITableDate extends IBookingTime, Date {
  state: string; // "selected"
}


export interface IBookingTime {
  vaildTablesAtTime: ITableItem[];
  vaidJoinsAtTime: ITableItem[];
  freeTablesAtTime: ITableItem[];
  table: ITableItem;
  bookingsAtTime: unknown[];
  coversReached: number;
  errorMgs: string[];
  maxCovers: number;
  open: boolean;
  service: string; // "Afternoon"
  status: TimeStatus; // "unavailable"
  time: Date; // Mon Sep 07 2020 15:10:00 GMT+1000 (Australian Eastern Standard Time) {}
  warningMsgs: string[];
}

export interface IBookingTagType {
  name: string; // "Occasion"
  type: string; // "tagtype"
  _id?: string; // "tagtype_occasion"
  id?: string;
  _rev?: string;
  $$hashKey?: string;
}

export interface IBookingTag {
  colour?: string; //"#000000"
  icon: string; //"birthday"
  name: string; //"Birthday"
  online: boolean;
  order?: number;
  permanent: boolean;
  tagtype: IBookingTagType;
  type: string; // "tag"
  _id: string; // "tag_birthday"
  selected?: boolean;
  editable?: boolean;
  noneditable?: boolean;
  _rev?: string;
}


export interface ICood {
  x: number;
  y: number;
}

export interface ISectionLookup {
  name: string; // "Beer Garden"
  order: number; // 1
  position: ICood;
  type: string; // "section"
  _id: string; // "section_9BTVP2I5KZMWI"
}


export interface IResponse<T> {
  statusText?: string; // OK
  status: number; // 200
  data: T;
}

export interface ITableSectionList {
    tableSelectionList: ITableItem[];
    tableSelectionListWithJoins: ITableItem[];
    tableStatusLookUps: any;
}

export interface IManageBookingConfig {
  title: string;
  message: string;
  hideDetails: boolean;
  hideButtons: boolean;
}

// very similar to IOwnedVenue, but not identical
export interface IVenue extends IVenueMinimal {
  id: number;
  accountId?: string;
  currency: string; // USD, AUD, GBP, etc
  timeZoneId: string;
  clientSideEncryptKey: string;
  analytics: IAnalyticsKeys;
  preAuthReleasingWindow?: number;
  maxPeoplePerBooking?: number;
}

export interface ILayoutMinimalAbstract {
  "id": string; // "layout_16QV80IGK18U2",
  "name": string; // "Garden Front Layout Spacious Rectangles",
  "sectionId": string; // "section_T4T83LMU3BFAO",
  "tables": ITableItem[];
  "tablesJoins": ITableItemAbstract[];
}

export interface ILayoutMinimal {
  "tablesJoins": ITableItem[];
}

export interface IBookingPayment {
  amountPaid?: number; // amountPaid will be undefined if pre-auth is not yet authorized
  price: number;
  fee?: number;
  paymentType: servicePaymentType;

  amountDue: number;
  discountAmount: number;
  peopleRequired: number;
  promotionCode?: string;
  discountApplied?: boolean;

  transactionId?: string;
  currency?: string; // think this is just for pre-auth
}


export const SECTION_ANY_ID = 'any';
export const SECTION_ANY_NAME = 'Any';


export interface IDetectPhone {
  isIPhone: boolean;
  isPhone: boolean;
}

export enum PaymentKind {
  stripeStandard = 0,
  stripe3DSecure = 1,
  stripePreauth = 2,
  ewayStandard = 3,
  ewayPreauth = 4
}

export enum PaymentTargetType {
  person = 'person',
  booking = 'booking'
}

// table join prefix for ids (front end only)
export const JOIN_PREFIX = 'join_';
