import {
    IOwnedVenue,
    ISchedule,
    IScheduleTime,
    ISectionOrder,
    IWidgetSettings,
    paymentProviderTypeUppercase,
    themeTypes
} from "shared-types/WidgetTypes";
import {
    IBookingTag,
    IServicePaymentDetails,
    IServicePaymentOption,
    ITableItem,
    servicePaymentType
} from "shared-types/SharedTypes";

const SERVICE_ID_1 = 'service_TEST1';
const SERVICE_NAME_1 = 'Dinner';

export class MocksService {

    static getSchedule(serviceId = SERVICE_ID_1, TIMES = TIMES1, name = "Jim's Awesome Pizzas", interval = 15): ISchedule {
        return {
            venueDetails: this.getOwnedVenue(1, 'mock-venue'),
            venueId: 268,
            "isVenueOpen": true,
            "blockoutMessage": null,
            "tags": [this.getTag(), this.getTag('tag_birthday_1'), this.getTag('tag_birthday_2')],
            "bookingInterval": interval,
            "services": [
                {
                    "id": serviceId,
                    "name": SERVICE_NAME_1,
                    "serviceType": 'DINNER',
                    "online": true,
                    "duration": 90,
                    "sections": this.getScheduleSections(),
                    "times": TIMES,
                    "paymentDetails": this.getPaymentDetails(),
                    "description": null,
                    "policyAgreement": null,
                    "policyAgreementText": null
                },
                {
                    "id": 'service_TEST2',
                    "name": 'Lunch',
                    "serviceType": 'LUNCH',
                    "online": true,
                    "duration": 90,
                    "sections": this.getScheduleSections(),
                    "times": TIMES2,
                    "paymentDetails": null,
                    "description": null,
                    "policyAgreement": null,
                    "policyAgreementText": null
                },
                {
                    "id": 'service_TEST23',
                    "name": 'Breakfast',
                    "serviceType": 'Breakfast',
                    "online": true,
                    "duration": 90,
                    "sections": this.getScheduleSections(),
                    "times": TIMES,
                    "paymentDetails": null,
                    "description": null,
                    "policyAgreement": null,
                    "policyAgreementText": null
                }
            ]
        }
    }

    private static getPaymentDetails(): IServicePaymentDetails {
        return {
            "paymentType": servicePaymentType.preAuth,
            "peopleRequired": 1,
            "price": 0.0,
            "options": [
                {
                    "id": "menuoption_EB80GWRQG9RKE_1617152382392",
                    "provider": "Nbi",
                    "name": "5 course degustation",
                    "label": "5 course degustation",
                    "description": null,
                    "link": null,
                    "tag": null,
                    "paymentType": servicePaymentType.preAuth,
                    "price": 150.0,
                    "childMenuOptionIds": [],
                    "explicitChildMenuOptionIds": []
                },
                {
                    "provider": "NBI",
                    "paymentType": servicePaymentType.noPayment,
                    "name": "Free test for child options displaying",
                    "label": "Free test for child options displaying",
                    "description": "Free test for child options displaying",
                    "tag": "tag_9ALSUIHYY2FK3_1579498671474",
                    "price": 0,
                    "childMenuOptionIds": [
                        "menuoption_M4OY8L8HTCLOR_1588827614800",
                    ],
                    "explicitChildMenuOptionIds": [
                        "menuoption_FPPHO43W8YGJ0_1579664125892"
                    ],
                    "id": "menuoption_Y811KJMGAK1WI_1593746952393",
                    "link": null,
                }
            ],
            "optionsFrom": null,
            "optionsTo": null,
            "maxPeoplePerBookingOverride": null,
            "hasPromotion": false,
            "singleMenuPerBooking": true,
        }
    }

    private static getServicePaymentOptionFullPayment(): IServicePaymentOption {
        return {
            "id": "menuoption_TEST2",
            "provider": "Nbi",
            "name": "Glass of wine",
            "label": "wine",
            "description": "Drink some wine with your meal",
            "link": "http://nowbookit.com",
            "tag": "tag_birthday",
            "paymentType": servicePaymentType.fullPayment,
            "price": 10,
            childMenuOptionIds: [],
            explicitChildMenuOptionIds: []
        }
    }

    static getTag(_id = "tag_birthday"): IBookingTag {
        return {
            "name": "Birthday",
            "icon": "birthday",
            "online": true,
            "tagtype": {
                "name": "Occasion",
                "_id": "tagtype_occasion",
                "type": "tagtype"
            },
            "order": 1,
            "colour": "#000000",
            "permanent": false,
            "_id": _id,
            "type": "tag"
        }
    }

    static getScheduleSections(): ISectionOrder[] {
        return [
            {
                "id": "section_GIYOEZ9CC963K",
                "name": "Main Room",
                "order": 0
            },
            {
                "id": "section_AEMOGNHCZ4IV0",
                "name": "Cellar",
                "order": 1
            },
            {
                "id": "section_WA2EW2CZKJB5Y",
                "name": "Front Room",
                "order": 2
            },
            {
                "id": "section_5774RYT53CRAN",
                "name": "Back Room",
                "order": 3
            },
            {
                "id": "section_NJNFREFWU89BJ",
                "name": "Rooftop",
                "order": 4
            }
        ]
    }

    private static make2Digit(num: number): string {
        if (num < 10) {
            return `0${num}`;
        }
        return num.toString();
    }

    static getScheduleTime(hour24 = 18, mins = 30, day = 15, month = 6, year = 2030): IScheduleTime {
        return {
            "name": `${this.make2Digit(hour24 > 12 ? hour24 - 12 : hour24)}:${this.make2Digit(mins)}${hour24 >= 12 ? 'pm' : 'am'}`,
            "expired": false,
            "time": `${this.make2Digit(year)}-${this.make2Digit(month)}-${this.make2Digit(day)}T${this.make2Digit(hour24)}:${this.make2Digit(mins)}:00`,
            "sections": [
                {
                    "id": "section_GIYOEZ9CC963K",
                    "sectionState": true,
                    isSectionBlocked: false
                },
                {
                    "id": "section_AEMOGNHCZ4IV0",
                    "sectionState": true,
                    isSectionBlocked: false
                },
                {
                    "id": "section_WA2EW2CZKJB5Y",
                    "sectionState": true,
                    isSectionBlocked: false
                },
                {
                    "id": "section_5774RYT53CRAN",
                    "sectionState": true,
                    isSectionBlocked: false
                },
                {
                    "id": "section_NJNFREFWU89BJ",
                    "sectionState": true,
                    isSectionBlocked: false
                }
            ],
            onlySharedTablesRemain: false,
        }
    }

    static getOwnedVenue(id: number, name: string, paymentProvider: paymentProviderTypeUppercase = paymentProviderTypeUppercase.Stripe): IOwnedVenue {

        return {
            id,
            "name": name,
            "country": "AU",
            "address": "123 Abc Street",
            "suburb": "Genericville",
            "postcode": "2000",
            "state": "NSW",
            "phone": "+61 414 562 222",
            "url": null,
            "active": true,
            "logoUrl": null,
            "alternatePhone": null,
            "contactEmail": "jim@nowbookit.com",
            widgetSettings: this.getWidgetSettings(),
            "timeZoneId": "Australia/Sydney",
            "currency": "AUD",
            "paymentSettings": {
                "paymentProvider": paymentProvider,
                "eway": {
                    "acceptVisa": true,
                    "acceptMasterCard": true,
                    "acceptAmericanExpress": true,
                    "acceptDiners": false,
                    "acceptJcb": false,
                    "clientSideEncryptionKey": null,
                    "paymentProvider": null // will get set to paymentProviderType.eway in AccountService -> getPaymentSettings
                },
                "stripe": {
                    "publishableKey": "pk_test_8hMNVRTOR3TdRU7FAoB2BGgs00YUujT44j",
                    "paymentProvider": null, // will get set to paymentProviderType.stripe in AccountService -> getPaymentSettings
                    "stripe3DEnabled": false
                },
                "preAuthReleasingWindow": 24
            },
            "canCustomersCancelBookings": true,
            "canCustomersEditBookings": true,
            "canCancelBookingBySms": false,
            "canEditBookingBySms": false,
            "smsEnabled": false,
            "analytics": {
                "facebook": {
                    "apiKey": null
                },
                "google": {
                    "apiKey": null
                }
            }
        }
    }

    static getTables() {
        return tableSelectionList as unknown as ITableItem[];
    }

    static getWidgetSettings(): IWidgetSettings {
        return {
            "maximumPeopleMessage": "Please contact us for bookings larger than {{maxPeoplePerBooking}} on {{phone}}",
            "dayClosedMessage": "Unfortunately we are closed on this day, but would love to see you another day.",
            "noTablesAvailableMessage": "Tables are very limited at this time. Please call us on {{phone}}  for more availability.",
            "timeNoLongerAvailableMessage": "Tables are very limited at this time. Please call us on {{phone}} for more availability.",
            "showTimeNotAvailable": false,
            "timeNotAvailableMessage": "Call Us",
            "maxPeoplePerBooking": 10,
            "canCustomersChooseSection": true,
            "termsAndConditionsMessage": "1. As a courtesy you agree to contact the restaurant if you have any changes, updates or need to cancel your booking.\n2. If you use our Sites and Services, we may communicate with you via electronic messages, including email, text message/SMS, or mobile push notifications in accordance with our privacy policy.\n3. Your booking will be allocated to the best available table in the venue you have chosen.\n4. Any special requests made will be catered for by the venue as best as possible, please contact the venue directly if you require confirmation of your request.\n5. Your information will be used in accordance with the Now Book It Privacy Policy [https://www.nowbookit.com/privacypolicy](https://www.nowbookit.com/privacypolicy).",
            "publicTermsAndConditionsMessage": "1. As a courtesy you agree to contact the restaurant if you have any changes, updates or need to cancel your booking.\n2. If you use our Sites and Services, we may communicate with you via electronic messages, including email, text message/SMS, or mobile push notifications in accordance with our privacy policy.\n3. Your booking will be allocated to the best available table in the venue you have chosen.\n4. Any special requests made will be catered for by the venue as best as possible, please contact the venue directly if you require confirmation of your request.\n5. Your information will be used in accordance with the Now Book It Privacy Policy [https://www.nowbookit.com/privacypolicy](https://www.nowbookit.com/privacypolicy).",
            "preAuthorisationMessage": "To take this booking we require a credit card pre-authorisation. Nothing will be debited from your card when making the booking, however, if you cancel within 24 hours of your booking or don't show up, a cancellation fee per person will be taken as specified.",
            "bookingCancellationWindow": "24",
            "theme": themeTypes.outlinedLight,
            "accentColour": "hex,bf360c,6d4c41",
            "font": "Josefin Sans",
            "button": "blue_key_sans_rect_now",
            "allowOnlineBookings": true,
            "onlineBookingsOffMessage": "Sorry, online bookings are unavailable at the moment, please call us on {{phone}}.",
            "minMinutesBeforeServiceBooking": null,
            "maxDaysInFutureBooking": 180,
            "tooFarInAdvanceMessage": "Sorry, we don't take online bookings this far in advance. Please call us on {{phone}}.",
            "disableEditingTimeWindow": null,
            "finalWidgetScreenMessage": "Thanks for your booking, we can't wait to see you. We've sent you an email with the details of this booking.",
            "minMinutesBeforeBooking": null,
            "disableCancelTimeWindowInHours" : 5
        }
    }
}


export const tables = [{
    "name": 1,
    "order": 0,
    "shared": false,
    "priority": 1,
    "capacity": 4,
    "minCapacity": 1,
    "online": true,
    "walkinOnly": false,
    "availableOption": "all",
    "shape": "square",
    "sectionId": "section_G7ZSB662ZNKOC",
    "_id": "table_PN8UP46W3MJG1_1619657274484",
    "free": true,
    "valid": true
}, {
    "name": 2,
    "order": 1,
    "shared": false,
    "priority": 1,
    "capacity": 4,
    "minCapacity": 1,
    "online": true,
    "walkinOnly": false,
    "availableOption": "all",
    "shape": "square",
    "sectionId": "section_G7ZSB662ZNKOC",
    "_id": "table_KI5BJPN87UM0U_1619657274484",
    "_rev": "1-8aced8aa77c45191d130c4581830ed81",
    "$$hashKey": "object:475",
    "free": true,
    "valid": true
}, {
    "name": 3,
    "order": 2,
    "shared": false,
    "priority": 1,
    "capacity": 4,
    "minCapacity": 1,
    "online": true,
    "walkinOnly": false,
    "availableOption": "all",
    "shape": "square",
    "sectionId": "section_G7ZSB662ZNKOC",
    "_id": "table_7K36UPL1PK4L0_1619657274484",
    "_rev": "1-4b6a42d46141d0e1f769c9c5af7e18e9",
    "$$hashKey": "object:476",
    "free": true,
    "valid": true
}, {
    "name": 4,
    "order": 3,
    "shared": false,
    "priority": 1,
    "capacity": 4,
    "minCapacity": 1,
    "online": true,
    "walkinOnly": false,
    "availableOption": "all",
    "shape": "square",
    "sectionId": "section_G7ZSB662ZNKOC",
    "_id": "table_J0TSF9BYP0IZR_1619657274484",
    "_rev": "1-b3f8867d6f92a2e927ebbe69e76e4cc0",
    "$$hashKey": "object:477",
    "free": true,
    "valid": true
}, {
    "name": 5,
    "order": 4,
    "shared": false,
    "priority": 1,
    "capacity": 8,
    "minCapacity": 5,
    "online": true,
    "walkinOnly": false,
    "availableOption": "all",
    "shape": "rect",
    "sectionId": "section_G7ZSB662ZNKOC",
    "_id": "table_BR7JIHK9RM5PB_1619657274484",
    "_rev": "1-72171b6b18efc9253bf243bf0979804b",
    "$$hashKey": "object:478",
    "free": true,
    "valid": false
}, {
    "name": 6,
    "order": 5,
    "shared": false,
    "priority": 1,
    "capacity": 8,
    "minCapacity": 5,
    "online": true,
    "walkinOnly": false,
    "availableOption": "all",
    "shape": "rect",
    "sectionId": "section_G7ZSB662ZNKOC",
    "_id": "table_OO7KJUMZ45VZT_1619657274484",
    "_rev": "1-d3277b2c6a94a056638ef53054e32b52",
    "$$hashKey": "object:479",
    "free": true,
    "valid": false
}, {
    "name": 7,
    "order": 6,
    "shared": false,
    "priority": 1,
    "capacity": 10,
    "minCapacity": 5,
    "online": true,
    "walkinOnly": false,
    "availableOption": "all",
    "shape": "rect",
    "sectionId": "section_G7ZSB662ZNKOC",
    "_id": "table_VTHAA7F82RJ8B_1619657274484",
    "_rev": "1-528124920d19bf1b99e6f1c4828bd16a",
    "$$hashKey": "object:480",
    "free": true,
    "valid": false
}, {
    "name": 8,
    "order": 7,
    "shared": false,
    "priority": 1,
    "capacity": 10,
    "minCapacity": 5,
    "online": true,
    "walkinOnly": false,
    "availableOption": "all",
    "shape": "rect",
    "sectionId": "section_G7ZSB662ZNKOC",
    "_id": "table_3YPUOHRIOUMEY_1619657274484",
    "_rev": "1-97070dca69b25678d912eb5beadecfa6",
    "$$hashKey": "object:481",
    "free": true,
    "valid": false
}, {
    "name": 9,
    "order": 0,
    "shared": false,
    "priority": 1,
    "capacity": 4,
    "minCapacity": 1,
    "online": true,
    "walkinOnly": false,
    "availableOption": "all",
    "shape": "square",
    "sectionId": "section_YAGI34318YRUB",
    "_id": "table_13TOL8C3ELVW4_1619657274484",
    "_rev": "1-88bf14e88bfd3f835d143e76c36304b4",
    "$$hashKey": "object:506",
    "free": true,
    "valid": true
}, {
    "name": 10,
    "order": 1,
    "shared": false,
    "priority": 1,
    "capacity": 4,
    "minCapacity": 1,
    "online": true,
    "walkinOnly": false,
    "availableOption": "all",
    "shape": "square",
    "sectionId": "section_YAGI34318YRUB",
    "_id": "table_O70QLQQ4PRMRK_1619657274484",
    "_rev": "1-03b30a736cace4ccf77aee35b54eb1f8",
    "$$hashKey": "object:507",
    "free": true,
    "valid": true
}, {
    "name": 11,
    "order": 2,
    "shared": false,
    "priority": 1,
    "capacity": 4,
    "minCapacity": 2,
    "online": true,
    "walkinOnly": false,
    "availableOption": "all",
    "shape": "square",
    "sectionId": "section_YAGI34318YRUB",
    "_id": "table_5WL3SB3QSVWVL_1619657274484",
    "_rev": "1-0430a97821c1acbaff6891d56be1b3b7",
    "$$hashKey": "object:508",
    "free": true,
    "valid": true
}, {
    "name": 12,
    "order": 3,
    "shared": false,
    "priority": 1,
    "capacity": 4,
    "minCapacity": 2,
    "online": true,
    "walkinOnly": false,
    "availableOption": "all",
    "shape": "square",
    "sectionId": "section_YAGI34318YRUB",
    "_id": "table_O8HYAYTFMPFZF_1619657274484",
    "_rev": "1-be652e06f54974b0b8375ec9d958aaef",
    "$$hashKey": "object:509",
    "free": true,
    "valid": true
}, {
    "name": 13,
    "order": 4,
    "shared": false,
    "priority": 1,
    "capacity": 6,
    "minCapacity": 5,
    "online": true,
    "walkinOnly": false,
    "availableOption": "all",
    "shape": "rect",
    "sectionId": "section_YAGI34318YRUB",
    "_id": "table_TN3JFMJ189O8T_1619657274484",
    "_rev": "1-df074d7e35c39e86877fa168feb6c740",
    "$$hashKey": "object:510",
    "free": true,
    "valid": false
}, {
    "name": 14,
    "order": 5,
    "shared": false,
    "priority": 1,
    "capacity": 6,
    "minCapacity": 5,
    "online": true,
    "walkinOnly": false,
    "availableOption": "all",
    "shape": "rect",
    "sectionId": "section_YAGI34318YRUB",
    "_id": "table_WPR6E75YEOHWM_1619657274484",
    "_rev": "1-fdb6f43fbfb109b96d44a9adb08f5c13",
    "$$hashKey": "object:511",
    "free": true,
    "valid": false
}]

export const TIMES2: IScheduleTime[] = [
    {
        "name": "12:20pm",
        "expired": false,
        "bookingOptionsCount": 0,
        "time": "2021-04-09T12:20:00",
        "sections": [
            {
                "id": "section_Z9EH37K3UHV26",
                "sectionState": true,
                "isSectionBlocked": false
            },
            {
                "id": "section_3YKZ2S8VK0YF1",
                "sectionState": false,
                "isSectionBlocked": true
            },
            {
                "id": "section_N97BGLRO3ZGZ5",
                "sectionState": true,
                "isSectionBlocked": false
            },
            {
                "id": "section_GVJOMU5TMERKC",
                "sectionState": true,
                "isSectionBlocked": false
            }
        ],
        onlySharedTablesRemain: false
    },
    // {
    //     "name": "12:40pm",
    //     "expired": false,
    //     "bookingOptionsCount": 0,
    //     "time": "2021-04-09T12:40:00",
    //     "sections": [
    //         {
    //             "id": "section_Z9EH37K3UHV26",
    //             "sectionState": true,
    //             "isSectionBlocked": false
    //         },
    //         {
    //             "id": "section_3YKZ2S8VK0YF1",
    //             "sectionState": false,
    //             "isSectionBlocked": true
    //         },
    //         {
    //             "id": "section_N97BGLRO3ZGZ5",
    //             "sectionState": true,
    //             "isSectionBlocked": false
    //         },
    //         {
    //             "id": "section_GVJOMU5TMERKC",
    //             "sectionState": true,
    //             "isSectionBlocked": false
    //         }
    //     ]
    // },
    // {
    //     "name": "12:30pm",
    //     "expired": false,
    //     "bookingOptionsCount": 0,
    //     "time": "2021-04-09T12:30:00",
    //     "sections": [
    //         {
    //             "id": "section_Z9EH37K3UHV26",
    //             "sectionState": true,
    //             "isSectionBlocked": false
    //         },
    //         {
    //             "id": "section_3YKZ2S8VK0YF1",
    //             "sectionState": false,
    //             "isSectionBlocked": true
    //         },
    //         {
    //             "id": "section_N97BGLRO3ZGZ5",
    //             "sectionState": true,
    //             "isSectionBlocked": false
    //         },
    //         {
    //             "id": "section_GVJOMU5TMERKC",
    //             "sectionState": true,
    //             "isSectionBlocked": false
    //         }
    //     ]
    // },
    // {
    //     "name": "12:40pm",
    //     "expired": false,
    //     "bookingOptionsCount": 0,
    //     "time": "2021-04-09T12:40:00",
    //     "sections": [
    //         {
    //             "id": "section_Z9EH37K3UHV26",
    //             "sectionState": true,
    //             "isSectionBlocked": false
    //         },
    //         {
    //             "id": "section_3YKZ2S8VK0YF1",
    //             "sectionState": false,
    //             "isSectionBlocked": true
    //         },
    //         {
    //             "id": "section_N97BGLRO3ZGZ5",
    //             "sectionState": true,
    //             "isSectionBlocked": false
    //         },
    //         {
    //             "id": "section_GVJOMU5TMERKC",
    //             "sectionState": true,
    //             "isSectionBlocked": false
    //         }
    //     ]
    // },
    // {
    //     "name": "12:50pm",
    //     "expired": false,
    //     "bookingOptionsCount": 0,
    //     "time": "2021-04-09T12:50:00",
    //     "sections": [
    //         {
    //             "id": "section_Z9EH37K3UHV26",
    //             "sectionState": true,
    //             "isSectionBlocked": false
    //         },
    //         {
    //             "id": "section_3YKZ2S8VK0YF1",
    //             "sectionState": false,
    //             "isSectionBlocked": true
    //         },
    //         {
    //             "id": "section_N97BGLRO3ZGZ5",
    //             "sectionState": true,
    //             "isSectionBlocked": false
    //         },
    //         {
    //             "id": "section_GVJOMU5TMERKC",
    //             "sectionState": true,
    //             "isSectionBlocked": false
    //         }
    //     ]
    // },
    {
        "name": "1:20pm",
        "expired": false,
        "bookingOptionsCount": 0,
        "time": "2021-04-09T13:20:00",
        "sections": [
            {
                "id": "section_Z9EH37K3UHV26",
                "sectionState": true,
                "isSectionBlocked": false
            },
            {
                "id": "section_3YKZ2S8VK0YF1",
                "sectionState": false,
                "isSectionBlocked": true
            },
            {
                "id": "section_N97BGLRO3ZGZ5",
                "sectionState": true,
                "isSectionBlocked": false
            },
            {
                "id": "section_GVJOMU5TMERKC",
                "sectionState": true,
                "isSectionBlocked": false
            }
        ],
        onlySharedTablesRemain: false
    },
    // {
    //     "name": "1:30pm",
    //     "expired": false,
    //     "bookingOptionsCount": 0,
    //     "time": "2021-04-09T13:30:00",
    //     "sections": [
    //         {
    //             "id": "section_Z9EH37K3UHV26",
    //             "sectionState": true,
    //             "isSectionBlocked": false
    //         },
    //         {
    //             "id": "section_3YKZ2S8VK0YF1",
    //             "sectionState": false,
    //             "isSectionBlocked": true
    //         },
    //         {
    //             "id": "section_N97BGLRO3ZGZ5",
    //             "sectionState": true,
    //             "isSectionBlocked": false
    //         },
    //         {
    //             "id": "section_GVJOMU5TMERKC",
    //             "sectionState": true,
    //             "isSectionBlocked": false
    //         }
    //     ]
    // },
    // {
    //     "name": "1:45pm",
    //     "expired": false,
    //     "bookingOptionsCount": 0,
    //     "time": "2021-04-09T13:45:00",
    //     "sections": [
    //         {
    //             "id": "section_Z9EH37K3UHV26",
    //             "sectionState": true,
    //             "isSectionBlocked": false
    //         },
    //         {
    //             "id": "section_3YKZ2S8VK0YF1",
    //             "sectionState": false,
    //             "isSectionBlocked": true
    //         },
    //         {
    //             "id": "section_N97BGLRO3ZGZ5",
    //             "sectionState": true,
    //             "isSectionBlocked": false
    //         },
    //         {
    //             "id": "section_GVJOMU5TMERKC",
    //             "sectionState": true,
    //             "isSectionBlocked": false
    //         }
    //     ]
    // }
];

export const TIMES1: IScheduleTime[] = [
    // {
    //     "name": "10:00am",
    //     "expired": false,
    //     "time": "2021-04-09T10:00:00",
    //     "sections": [
    //         {
    //             "id": "section_IEK1YBOOZRQQK",
    //             "sectionState": true
    //         }
    //     ]
    // },
    {
        "name": "12:15pm",
        "expired": false,
        "time": "2021-04-09T12:45:00",
        onlySharedTablesRemain: false,
        "sections": [
            {
                "id": "section_IEK1YBOOZRQQK",
                "sectionState": true,
                isSectionBlocked: false
            }
        ]
    },
    {
        "name": "12:45pm",
        "expired": false,
        "time": "2021-04-09T12:45:00",
        onlySharedTablesRemain: false,
        "sections": [
            {
                "id": "section_IEK1YBOOZRQQK",
                "sectionState": true,
                isSectionBlocked: false
            }
        ]
    },
    {
        "name": "6:45pm",
        "expired": false,
        "time": "2021-04-09T18:45:00",
        onlySharedTablesRemain: false,
        "sections": [
            {
                "id": "section_IEK1YBOOZRQQK",
                "sectionState": true,
                isSectionBlocked: false
            }
        ]
    },
    {
        "name": "7:00pm",
        "expired": false,
        "time": "2021-04-09T19:00:00",
        onlySharedTablesRemain: false,
        "sections": [
            {
                "id": "section_IEK1YBOOZRQQK",
                "sectionState": true,
                isSectionBlocked: false
            }
        ]
    },
    {
        "name": "7:15pm",
        "expired": false,
        "time": "2021-04-09T19:15:00",
        onlySharedTablesRemain: false,
        "sections": [
            {
                "id": "section_IEK1YBOOZRQQK",
                "sectionState": true,
                isSectionBlocked: false
            }
        ]
    },
    {
        "name": "7:30pm",
        "expired": false,
        "time": "2021-04-09T19:30:00",
        onlySharedTablesRemain: false,
        "sections": [
            {
                "id": "section_IEK1YBOOZRQQK",
                "sectionState": true,
                isSectionBlocked: false
            }
        ]
    },
    {
        "name": "7:45pm",
        "expired": false,
        "time": "2021-04-09T19:45:00",
        onlySharedTablesRemain: false,
        "sections": [
            {
                "id": "section_IEK1YBOOZRQQK",
                "sectionState": true,
                isSectionBlocked: false
            }
        ]
    }
];

export const tableSelectionList = [
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "name": 1,
        "order": 0,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_4JKBI8FPLNEIQ",
        "position": {
            "x": 100,
            "y": 80
        },
        "_id": "table_0GI9TQJS6IF78_1619772484967",
        "_rev": "1-ab8d04293a99ebde1c58723343014f8e",
        "free": true,
        "valid": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": 2,
        "order": 1,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_4JKBI8FPLNEIQ",
        "type": "table",
        "position": {
            "x": 60,
            "y": 260
        },
        "_id": "table_5NGN7BBQHK1JH_1619772484967",
        "_rev": "1-0d7cec2cdc9b59765bf25b817f4149ab",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 10,
        "minCapacity": 1,
        "rotation": 0,
        "name": 3,
        "order": 2,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_4JKBI8FPLNEIQ",
        "type": "table",
        "position": {
            "x": 280,
            "y": 140
        },
        "_id": "table_GQJA0YZ8MPE5I_1619772484967",
        "_rev": "1-73aa622f02636c41f932d15dd4ddb380",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": 4,
        "order": 3,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_4JKBI8FPLNEIQ",
        "type": "table",
        "position": {
            "x": 40,
            "y": 380
        },
        "_id": "table_2U48CKQWLZC48_1619772484967",
        "_rev": "1-efc766c4d1b0f05b72919a4bfd7f23e4",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": "5",
        "order": 4,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_4JKBI8FPLNEIQ",
        "type": "table",
        "position": {
            "x": 220,
            "y": 540
        },
        "_id": "table_3095ZOGFI8N6U_1619772484967",
        "_rev": "1-00838a06e4c5cd38f72908a344c75293",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": "5.0",
        "order": 5,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_4JKBI8FPLNEIQ",
        "type": "table",
        "position": {
            "x": 20,
            "y": 620
        },
        "_id": "table_I4286ZECNVRRQ_1619772484967",
        "_rev": "1-da1e0bf03ea09f306dc90bd47440c91b",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 8,
        "minCapacity": 6,
        "rotation": 0,
        "name": 7,
        "order": 6,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_4JKBI8FPLNEIQ",
        "type": "table",
        "position": {
            "x": 520,
            "y": 520
        },
        "_id": "table_ZEP97B7QMFY5R_1619772484967",
        "_rev": "1-c53180769246d94f28a7ff7e0809d884",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": "7.0",
        "order": 7,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_4JKBI8FPLNEIQ",
        "type": "table",
        "position": {
            "x": 640,
            "y": 140
        },
        "_id": "table_VC66JB4I25191_1619772484967",
        "_rev": "1-1fe1bed2245465feaa62230a9fec98e6",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 6,
        "minCapacity": 5,
        "rotation": 0,
        "name": 9,
        "order": 8,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_4JKBI8FPLNEIQ",
        "type": "table",
        "position": {
            "x": 240,
            "y": 680
        },
        "_id": "table_JNOI7PUUVI0S2_1619772484967",
        "_rev": "1-c4f906dbc7b04c5f3961927461213346",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 9,
        "minCapacity": 1,
        "rotation": 0,
        "name": 10,
        "order": 9,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_4JKBI8FPLNEIQ",
        "type": "table",
        "position": {
            "x": 80,
            "y": 860
        },
        "_id": "table_5MKAE8QU7TBTK_1619772484967",
        "_rev": "1-3fc6df27b6d9c186ed7dcbcb2597db08",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": 11,
        "order": 10,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_4JKBI8FPLNEIQ",
        "type": "table",
        "position": {
            "x": 340,
            "y": 440
        },
        "_id": "table_5WIM0ZJG4CF6H_1619772484967",
        "_rev": "1-83c34eb1043069ddf78ecbdb59497b02",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": 12,
        "order": 11,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_4JKBI8FPLNEIQ",
        "type": "table",
        "position": {
            "x": 680,
            "y": 320
        },
        "_id": "table_2N0OIB5O22PIL_1619772484967",
        "_rev": "1-4b81e4b8df91a9765f53cbe2d8500d0d",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": 13,
        "order": 12,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_4JKBI8FPLNEIQ",
        "type": "table",
        "position": {
            "x": 860,
            "y": 100
        },
        "_id": "table_T0MZ4588KSKWS_1619772484967",
        "_rev": "1-ced9bf3c02861c2112dff9dadef1d675",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": 14,
        "order": 13,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_4JKBI8FPLNEIQ",
        "type": "table",
        "position": {
            "x": 920,
            "y": 340
        },
        "_id": "table_3UHLLFN8ZYGT8_1619772484967",
        "_rev": "1-baa893656cfd8efa638e82e81392784c",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": 15,
        "order": 14,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_4JKBI8FPLNEIQ",
        "type": "table",
        "position": {
            "x": 840,
            "y": 520
        },
        "_id": "table_PMBSOYQKHN1S1_1619772484967",
        "_rev": "1-3a2b58081f3a0b630b21f16cec3aa9b7",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": 2,
        "order": 1,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_6IK74Z020MV3A",
        "type": "table",
        "position": {
            "x": 20,
            "y": 160
        },
        "_id": "table_3QQS9Z6KA449Q_1549440036254",
        "_rev": "23-b4ff106d5e0e0bbb6a5ffe7266f7dc41",
        "free": true,
        "valid": false,
        "isAltBg": true
    },
    {
        "online": true,
        "shared": false,
        "capacity": 9,
        "minCapacity": 1,
        "rotation": 0,
        "name": 4,
        "order": 3,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_6IK74Z020MV3A",
        "type": "table",
        "position": {
            "x": 300,
            "y": 100
        },
        "_id": "table_C26YYGEVPON7K_1549440036254",
        "_rev": "23-a3236ade18e1e31bab2d701d2306e909",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 12,
        "minCapacity": 1,
        "rotation": 0,
        "name": "5",
        "order": 4,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_6IK74Z020MV3A",
        "type": "table",
        "position": {
            "x": 440,
            "y": 500
        },
        "_id": "table_SN0GHUQ2OCNJI_1549440036254",
        "_rev": "23-1998901d7a57dc68d54ea3c49140dae1",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 18,
        "minCapacity": 6,
        "rotation": 0,
        "name": 7,
        "order": 6,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_6IK74Z020MV3A",
        "type": "table",
        "position": {
            "x": 60,
            "y": 740
        },
        "_id": "table_TC8LK3P9GNTA2_1549440036255",
        "_rev": "23-b3ba6aeac9d5ee3c2c6d977d516fec17",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": "7.0",
        "order": 7,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_6IK74Z020MV3A",
        "type": "table",
        "position": {
            "x": 260,
            "y": 700
        },
        "_id": "table_NP2GBPO8W4V79_1549440036255",
        "_rev": "23-449b973faa61e9e6e738d7ca11e2e309",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 6,
        "minCapacity": 5,
        "rotation": 0,
        "name": 9,
        "order": 8,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_6IK74Z020MV3A",
        "type": "table",
        "position": {
            "x": 620,
            "y": 220
        },
        "_id": "table_J3K28IPYGBL0K_1549440036255",
        "_rev": "23-6b95d6b2fa8caef7a582e8297a1ec80f",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 9,
        "minCapacity": 1,
        "rotation": 0,
        "name": 10,
        "order": 9,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_6IK74Z020MV3A",
        "type": "table",
        "position": {
            "x": 800,
            "y": 500
        },
        "_id": "table_2BK2FGN3J3A8R_1549440036255",
        "_rev": "23-89ece7e9a612c7dbecc4fd3677220590",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 5,
        "minCapacity": 1,
        "rotation": 0,
        "name": 11,
        "order": 10,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_6IK74Z020MV3A",
        "type": "table",
        "position": {
            "x": 640,
            "y": 20
        },
        "_id": "table_Q0G79KJOPVZUK_1549440036255",
        "_rev": "23-4a046e23d0b313e1111559b7251c677e",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 1,
        "minCapacity": 1,
        "rotation": 0,
        "name": 12,
        "order": 11,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_6IK74Z020MV3A",
        "type": "table",
        "position": {
            "x": 880,
            "y": 140
        },
        "_id": "table_2ECBGZ0N7QIUH_1549440036255",
        "_rev": "23-5a260a199a43722497dd26804a3413a5",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 7,
        "minCapacity": 1,
        "rotation": 0,
        "name": 13,
        "order": 12,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_6IK74Z020MV3A",
        "type": "table",
        "position": {
            "x": 1200,
            "y": 320
        },
        "_id": "table_J0LN1EJ0279QL_1549440036255",
        "_rev": "23-cb973ae5289d3f8b0e9afd24990a425a",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 8,
        "minCapacity": 1,
        "rotation": 0,
        "name": 14,
        "order": 13,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_6IK74Z020MV3A",
        "type": "table",
        "position": {
            "x": 740,
            "y": 900
        },
        "_id": "table_T2BSBYNWF5EHK_1549440036255",
        "_rev": "23-aa20ecfe335195c9b58be6631261354e",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": 1,
        "order": 0,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_P6ZME2ATPREN9",
        "type": "table",
        "position": {
            "x": 100,
            "y": 80
        },
        "_id": "table_2WEPVF1PTMSA7_1551303605195",
        "_rev": "4-a4697d88cc9dfdca4206837278cfaaf6",
        "free": true,
        "valid": false,
        "isAltBg": true
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": 2,
        "order": 1,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_P6ZME2ATPREN9",
        "type": "table",
        "position": {
            "x": 60,
            "y": 260
        },
        "_id": "table_KSN0H8JVCIMU1_1551303605195",
        "_rev": "4-18600f6fdb66c9b303520b0fd484c89e",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 10,
        "minCapacity": 1,
        "rotation": 0,
        "name": 3,
        "order": 2,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_P6ZME2ATPREN9",
        "type": "table",
        "position": {
            "x": 280,
            "y": 140
        },
        "_id": "table_F35QLFB552ENR_1551303605195",
        "_rev": "4-d09e6f5dceacbd5a9f71fa651e1a3512",
        "free": true,
        "valid": true,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": 4,
        "order": 3,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_P6ZME2ATPREN9",
        "type": "table",
        "position": {
            "x": 40,
            "y": 380
        },
        "_id": "table_IEIM87GRJ3GZ7_1551303605196",
        "_rev": "4-64d1f10cab74f055986f7bfbe0c4a4ec",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": "5",
        "order": 4,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_P6ZME2ATPREN9",
        "type": "table",
        "position": {
            "x": 220,
            "y": 540
        },
        "_id": "table_3MC7QZQ7I7HF0_1551303605196",
        "_rev": "4-68130d7fa1ef71adf45c45a4bd717033",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": "5.0",
        "order": 5,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_P6ZME2ATPREN9",
        "type": "table",
        "position": {
            "x": 20,
            "y": 620
        },
        "_id": "table_N8AKOHZ6C3BE9_1551303605196",
        "_rev": "4-086df05fe528916b0ec12112ca54bd13",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 8,
        "minCapacity": 6,
        "rotation": 0,
        "name": 7,
        "order": 6,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_P6ZME2ATPREN9",
        "type": "table",
        "position": {
            "x": 520,
            "y": 520
        },
        "_id": "table_84HM21UZ5A706_1551303605196",
        "_rev": "4-d577df862f19f062b16dd7202310e5ce",
        "free": true,
        "valid": true,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": "7.0",
        "order": 7,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_P6ZME2ATPREN9",
        "type": "table",
        "position": {
            "x": 640,
            "y": 140
        },
        "_id": "table_PKHI2SK0VL13M_1551303605196",
        "_rev": "4-22cdbbd629d6e766abad7cfa50b899c4",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 6,
        "minCapacity": 5,
        "rotation": 0,
        "name": 9,
        "order": 8,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_P6ZME2ATPREN9",
        "type": "table",
        "position": {
            "x": 240,
            "y": 680
        },
        "_id": "table_7AAVLHRNLZ4OM_1551303605196",
        "_rev": "4-e9132356af79a2b183f4175df5627752",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 9,
        "minCapacity": 1,
        "rotation": 0,
        "name": 10,
        "order": 9,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_P6ZME2ATPREN9",
        "type": "table",
        "position": {
            "x": 80,
            "y": 860
        },
        "_id": "table_6CFZPJBGPC0A0_1551303605196",
        "_rev": "4-acb559855c025cdd4d74fd43ade8f442",
        "free": true,
        "valid": true,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": 11,
        "order": 10,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_P6ZME2ATPREN9",
        "type": "table",
        "position": {
            "x": 340,
            "y": 440
        },
        "_id": "table_CVYPES1IBIAFH_1551303605196",
        "_rev": "4-4468eaa38b1dfda7cc109b5a99ae0dcc",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": 12,
        "order": 11,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_P6ZME2ATPREN9",
        "type": "table",
        "position": {
            "x": 680,
            "y": 320
        },
        "_id": "table_K1H1I9VR1RZLI_1551303605196",
        "_rev": "4-9e435aae2e9db84cd08aad24a7b9199c",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": 13,
        "order": 12,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_P6ZME2ATPREN9",
        "type": "table",
        "position": {
            "x": 860,
            "y": 100
        },
        "_id": "table_7BZ2SA6V68K1G_1551303605196",
        "_rev": "4-eb848678c95b98ef98aebde0bf723350",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": 14,
        "order": 13,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_P6ZME2ATPREN9",
        "type": "table",
        "position": {
            "x": 920,
            "y": 340
        },
        "_id": "table_L3GTC2FM9NP35_1551303605196",
        "_rev": "4-63df0fb4d6cffd322ada53a2504d11c7",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": 15,
        "order": 14,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_P6ZME2ATPREN9",
        "type": "table",
        "position": {
            "x": 840,
            "y": 520
        },
        "_id": "table_IV2Z00OEZGWW9_1551303605196",
        "_rev": "4-9694df104441039a8d9e5e3c1dbea2a4",
        "free": false,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 6,
        "minCapacity": 1,
        "rotation": 0,
        "name": 1,
        "order": 0,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_TVRY7RRZ65O98",
        "type": "table",
        "position": {
            "x": 100,
            "y": 100
        },
        "_id": "table_A068EMFFGY7YB_1557814519432",
        "_rev": "5-df2e82010b58dd52091b2a6be6f92975",
        "free": true,
        "valid": false,
        "isAltBg": true
    },
    {
        "online": true,
        "shared": false,
        "capacity": 3,
        "minCapacity": 1,
        "rotation": 0,
        "name": 2,
        "order": 1,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_TVRY7RRZ65O98",
        "type": "table",
        "position": {
            "x": 100,
            "y": 260
        },
        "_id": "table_585VLL7RUSB3O_1557814519433",
        "_rev": "5-632462a059551840dd7fc51a70d5c052",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 10,
        "minCapacity": 1,
        "rotation": 0,
        "name": 3,
        "order": 2,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_TVRY7RRZ65O98",
        "type": "table",
        "position": {
            "x": 240,
            "y": 360
        },
        "_id": "table_B6VZWIEEGKG0V_1557814519433",
        "_rev": "5-a3f295c3a76717f7e28cbe21e10e484a",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 4,
        "minCapacity": 1,
        "rotation": 0,
        "name": 4,
        "order": 3,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_TVRY7RRZ65O98",
        "type": "table",
        "position": {
            "x": 320,
            "y": 220
        },
        "_id": "table_A7YQLWA62U1KM_1557814519433",
        "_rev": "5-471e8805640d884e75c35f96b228cf04",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 5,
        "minCapacity": 1,
        "rotation": 0,
        "name": "5",
        "order": 4,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_TVRY7RRZ65O98",
        "type": "table",
        "position": {
            "x": 40,
            "y": 420
        },
        "_id": "table_UAFKQFQCHF4QV_1557814519434",
        "_rev": "5-1f45731a29bde4fec12ca2760efd6eb8",
        "free": true,
        "valid": false,
        "isAltBg": false
    },
    {
        "online": true,
        "shared": false,
        "capacity": 2,
        "minCapacity": 1,
        "rotation": 0,
        "name": "5.0",
        "order": 5,
        "priority": 1,
        "walkinOnly": false,
        "availableOption": "all",
        "shape": "square",
        "sectionId": "section_TVRY7RRZ65O98",
        "type": "table",
        "position": {
            "x": 140,
            "y": 580
        },
        "_id": "table_NE88ERM8GMOLS_1557814519434",
        "_rev": "5-f6462946a9b6d75edecaabc754f73fa8",
        "free": true,
        "valid": false,
        "isAltBg": false
    }
];

export const sectionsLookup = {
    "section_C0C3KCWSJW8UC": {
        "name": "test2Sectionsssss",
        "order": 0,
        "type": "section",
        "tableNumber": 23,
        "tablesStartAt": 3,
        "maxOccupancy": 10,
        "_id": "section_C0C3KCWSJW8UC",
        "_rev": "8-8affac1e36e06324c558c178112e9276",
        "$$hashKey": "object:500"
    },
    "section_4JKBI8FPLNEIQ": {
        "name": "Bar1Section",
        "order": 1,
        "type": "section",
        "tableNumber": 15,
        "tablesStartAt": 1,
        "position": {
            "x": 0,
            "y": 0
        },
        "maxOccupancy": 5,
        "_id": "section_4JKBI8FPLNEIQ",
        "_rev": "12-263e023fa429d4520c679c7a8f2077eb",
        "$$hashKey": "object:501"
    },
    "section_6IK74Z020MV3A": {
        "name": "Resto3Section",
        "order": 2,
        "type": "section",
        "position": {
            "x": 1235,
            "y": 0
        },
        "maxOccupancy": 10,
        "_id": "section_6IK74Z020MV3A",
        "_rev": "12-b0bd1ca2a3b4e14a2afcda84eb32e1b6",
        "$$hashKey": "object:502"
    },
    "section_P6ZME2ATPREN9": {
        "name": "Cellar4Section",
        "order": 3,
        "type": "section",
        "position": {
            "x": 535,
            "y": 992
        },
        "maxOccupancy": 10,
        "_id": "section_P6ZME2ATPREN9",
        "_rev": "12-39eaaa71d972d98b052d34f7a3b5f5a2",
        "$$hashKey": "object:503"
    },
    "section_TVRY7RRZ65O98": {
        "name": "Dining5Section",
        "order": 4,
        "type": "section",
        "position": {
            "x": 1588,
            "y": 1132
        },
        "maxOccupancy": 10,
        "_id": "section_TVRY7RRZ65O98",
        "_rev": "11-9cd5e81d30768679bf2ec294f2d7f287",
        "$$hashKey": "object:504"
    }
}

